import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { string, arrayOf } from 'prop-types';
import { Box, Typography } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import { LINK_TYPE } from '@/types';

const DynamicMenu = dynamic(() => import('../Menu'));

const Dropdown = ({ title, links }) => {
  const [value, setValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setValue(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setValue('');
  };

  return (
    <Box key={title} mr={2}>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          color: value === title ? 'primary.main' : 'inherit',
          backgroundColor: value === title ? 'neutral.lighter' : 'inherit',
          borderRadius: value === title ? 50 : 0,
          padding: 1,
          paddingLeft: 2,
          cursor: 'pointer',
          '&:hover': {
            color: 'primary.main',
            backgroundColor: 'neutral.lighter',
            borderRadius: 50,
            padding: 1,
            paddingLeft: 2,
          },
        }}
        onClick={event => handleMenu(event, title)}
      >
        <Typography variant="body2">{title}</Typography>
        {Boolean(anchorEl) && value === title ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
      </Box>
      {Boolean(anchorEl) && value === title && (
        <DynamicMenu anchorEl={anchorEl} handleClose={handleClose} links={links} value={value} title={title} />
      )}
    </Box>
  );
};

Dropdown.propTypes = {
  title: string.isRequired,
  links: arrayOf(LINK_TYPE).isRequired,
};

export default Dropdown;

import React from 'react';
import { shape, string, arrayOf } from 'prop-types';
import { Box } from '@mui/material';

import formatPhoneNumber from '@/utils/formatPhoneNumber';
import { LINK_TYPE } from '@/types';
import Link from '../../Link';
import Dropdown from './Dropdown';

const Nav = ({ columns, phoneNumber }) => (
  <Box display={{ xs: 'none', lg: 'flex' }} flexGrow={{ xs: 0, lg: 3 }} justifyContent="flex-end">
    {columns.map(column =>
      column.label ? (
        <Link
          key={column.label}
          href={column.href}
          mr={2}
          variant="body2"
          color="text.primary"
          display="flex"
          alignItems="center"
          sx={{
            padding: 1,
            '&:hover': {
              color: 'primary.main',
              backgroundColor: 'neutral.lighter',
              borderRadius: 50,
            },
          }}
        >
          {column.label}
        </Link>
      ) : (
        <Dropdown key={column.title} title={column.title} links={column.links} />
      ),
    )}
    {/* Phone Number (duplicated for large breakpoints) } */}
    <Link
      sx={{
        padding: 1,
        '&:hover': {
          color: 'primary.main',
          backgroundColor: 'neutral.lighter',
          borderRadius: 50,
        },
      }}
      href={`tel:${phoneNumber}`}
      variant="body2"
      color="text.primary"
      display="flex"
      alignItems="center"
    >
      {formatPhoneNumber(phoneNumber)}
    </Link>
  </Box>
);

Nav.propTypes = {
  phoneNumber: string.isRequired,
  columns: arrayOf(
    shape({
      label: string,
      href: string,
      title: string,
      links: arrayOf(LINK_TYPE),
    }),
  ).isRequired,
};

export default Nav;
